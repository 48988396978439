import React from 'react';
import '@google/model-viewer';
import '../App.css'
import { Button } from '@material-ui/core';
import { Helmet } from "react-helmet";
import ModelGlt from '../assets/building1.glb'
import ModelUsdz from '../assets/building.usdz'
import btnAR from '../assets/arIcon.svg';
import Environment from '../assets/environment.hdr';
export default function modelviewer() {
    return (
        <div>
            <Helmet>
                <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
            </Helmet>

            <model-viewer src={ModelGlt} camera-controls autoplay ar ar-modes="scene-viewer webxr quick-look" ios-src={ModelUsdz} environment-image={Environment}>
                <Button slot="ar-button" id="ar-button">
                    Visualize em seu ambiente
                </Button>
            </model-viewer>


        </div>
    )
}