import React from 'react'
import Modelviewer from '../components/modelviewer'

export default function Home() {
    return (
        <div>
            
           <Modelviewer /> 
        </div>
    )
}
